var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const nodeName = "tailr-ui-module.logic.fullscreen-loader";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Loading Spinner",
    category: "Logic",
    outputs: {
        ShowPopup: {
            type: "signal",
        },
    },
    signals: {
        Show() {
            return __awaiter(this, void 0, void 0, function* () {
                // If we have multiple show signals sent, only update the
                // running count
                if (Noodl.Variables.NumLoadingSpinnerRunning > 0) {
                    Noodl.Variables.NumLoadingSpinnerRunning++;
                }
                else {
                    // If this is the first show signal, show the popup
                    Noodl.Variables.NumLoadingSpinnerRunning = 1;
                    this.sendSignalOnOutput("ShowPopup");
                    const projectSettings = Noodl.getProjectSettings();
                    const component = projectSettings["fullscreen-loader"];
                    if (component) {
                        yield Noodl.Navigation.showPopup(component);
                    }
                    else {
                        throw new Error("Failed to get Fullscreen Loader component");
                    }
                }
            });
        },
        Hide() {
            // There could be cases where Show and Hide is called in the same tick
            // just in the wrong order.
            setImmediate(() => {
                // Decrease loading spinner running counter
                Noodl.Variables.NumLoadingSpinnerRunning--;
                // When we reach zero, hide the loading spinner
                if (Noodl.Variables.NumLoadingSpinnerRunning < 1) {
                    Noodl.Events.emit("Hide loader");
                }
            });
        },
    },
});
