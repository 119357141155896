import * as Noodl from "@noodl/noodl-sdk";
import * as LoadingSpinner from "./nodes/LoadingSpinner";
Noodl.defineModule({
    reactNodes: [],
    nodes: [LoadingSpinner.node],
    settings: [
        {
            type: "component",
            name: "fullscreen-loader",
            displayName: "Fullscreen Loader",
            group: "Tailr UI",
        },
    ],
    setup() { },
});
